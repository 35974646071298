<template>
  <h2>{{ custonTitle }}</h2>
  <p>{{ counter }} <sup>2</sup> = {{ squareCounter}}</p>

<div>
    <button @click="increment">+1</button>
    <button @click="deincrement">-1</button>
</div>
</template>

<script>
export default {
    props: {
        title: String,
        start: {
            type: Number,
            required: true,
            default: 1,
            validator(value){
                return value >= 1 

            }
        }
    },
    name: 'Counter',
    data() {
        return {
            counter: this.start
        }
    },
    methods: {
        increment() {
            this.counter * this.counter;
        },
        increment() {
            this.counter += 1
        },
        deincrement() {
            this.counter -= 1
        }
    },

    computed: {
        squareCounter() {
            console.log("computed squareCounter")
            return this.counter * this.counter
        },
        custonTitle() {
            return this.title || 'Counter'
        }
    }

}
</script>

<style>

button{
    background-color: #64BB87;
    border-radius: 5px;
    border: 1px solid white;
    color: white;
    cursor: pointer;
    margin: 0 5px;
    padding: 5px 15px;
    transition: 0.3s ease-in-out;

}

button:hover{
    background-color: #5aa67b;
    transition: 0.3s ease-in-out;
}

</style>